<template>
  <div
    class="studio-template"
    :class="{ 'studio-page': !$route.meta.hideTopBar }"
  >
    <div class="container-fluid text-center" v-if="isLoading">
      <HMSpinner class="mt-4" />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <StudentNavigation
            class="mt-4"
            v-if="!isLoading && $route.meta.showStudentNavigation"
          ></StudentNavigation>
        </div>
      </div>
    </div>
    <StudioTopBar
      v-if="!isLoading && !$route.meta.hideTopBar"
    ></StudioTopBar>
    <slot v-if="!isLoading && showRouterView"></slot>
    <ExpiredStudio v-if="!isLoading && !showRouterView" />

    <div class="container text-center studio-instagram" v-if="studio">
      <link
        :href="`https://fonts.googleapis.com/css2?family=${studio.default_font}:wght@400;500;700&display=swap`"
        rel="stylesheet"
      />
      <div class="mt-5"></div>
      <InstagramPhotosSlider
        class="mb-5"
        :studioId="studio.id"
        :key="studio.id"
        v-if="!$route.meta.hideInstagramSlider"
      />
    </div>
    <client-only>
      <HMCookieNotice
        class="cookie-notice"
        :class="{ 'cookie-notice--nav': !hideMobileNav }"
        v-if="!isPWA && !hideCookieNotice"
      />
    </client-only>
    <StudioMobileNavigation
      class="studio-mobile-navigation"
      v-if="!hideMobileNav"
    />
    <ViewAsTeacherFooter class="view-as-teacher-footer" />
    <StudioFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import InstagramPhotosSlider from '@/components/instagram/InstagramPhotosSlider.vue';
import { mapState } from 'pinia';
import StudioUpcomingEventModal from '@/components/events/StudioUpcomingEventModal.vue';
import ExpiredStudio from '@/components/shop/ExpiredStudio.vue';
import RefreshData from '@/mixins/refreshData';
import StudentLogin from '@/components/signup/StudentLogin.vue';
import StudioMobileNavigation from '@/components/navigation/StudioMobileNavigation.vue';
import StudentNavigation from '../components/navigation/StudentNavigation.vue';
import HMCookieNotice from '@/components/app/HMCookieNotice.vue';
import ViewAsTeacherFooter from '@/components/navigation/ViewAsTeacherFooter.vue';
import StudioTopBar from '@/components/navigation/StudioTopBar.vue';
import { useStudioStore, useGlobalsStore } from '@/stores';

export default defineComponent({
  name: 'StudioTemplate',
  setup() {
    const studioStore = useStudioStore();

    return {
      studioStore,
    }
  },
  // mixins: [RefreshData],
  components: {
    InstagramPhotosSlider,
    ExpiredStudio,
    StudioTopBar,
    StudioMobileNavigation,
    HMCookieNotice,
    ViewAsTeacherFooter,
    StudentNavigation,
  },
  computed: {
    ...mapState(useStudioStore, {
      studio: 'currentStudio',
      eventStartingSoon: 'eventStartingSoon',
    }),
    ...mapState(useGlobalsStore, {
      showLoginModal: 'showLoginModal',
      isPWA: 'isPWA',
      showUserbackInStudio: 'showUserbackInStudio',
    }),
    showRouterView() {
      if (!this.studio || this.$route.meta?.ignoreStudioExpiration) {
        return true;
      }
      return !this.studio.is_expired;
    },
    hideMobileNav() {
      return this.$route.meta.hideMobileNav;
    },
    hideCookieNotice() {
      return this.$route.meta?.hideCookieNotice;
    },
  },
  data() {
    return {
      isLoading: false,
      showNavDropdown: false,
    };
  },
  methods: {
    openLoginModal() {
      this.$modal.show(StudentLogin, {
        onLoginFn: () => {
          useGlobalsStore().setShowLoginModal(false);
          this.$toast.clear();
        },
      });
    },
  },
  watch: {
    eventStartingSoon: {
      handler(event) {
        if (event) {
          this.$modal.show(StudioUpcomingEventModal, {
            event: event.event,
            onClose: () => {
              useStudioStore().clearEventStartingSoon();
            },
          });
        }
      },
      immediate: true,
    },
    showLoginModal: {
      handler(show) {
        if (show) this.openLoginModal();
      },
      immediate: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.studio-mobile-navigation {
  @media screen and (min-width: 768px) {
    display: none;
  }
}
.studio-template .cookie-notice--nav {
  @media screen and (max-width: 768px) {
    bottom: 70px;
  }
}

.view-as-teacher-footer {
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
  }
}
</style>
