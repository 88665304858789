import { default as aboutA1ISln1EmwMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue?macro=true";
import { default as eventsjmjGLJavcHMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93OoAiASsvwlMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45sampleLJX88mm4otMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productsSWxaEDUnhrMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93FoW068XwaeMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as viewmIiwBRDne8Meta } from "/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexu0INxbu4GoMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93XwsY95PRwxMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexo8BQAHzNAEMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_932XN80veQr2Meta } from "/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as indexv6tJdTvMw4Meta } from "/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionsf3ZmN6pACdMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordingsGC3qaL8IgwMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsl7yVZt7yaUMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93dvrjsDzOZGMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue?macro=true";
import { default as index6j3K4AKFUgMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/buy/product/[product_id]/index.vue?macro=true";
import { default as confirm9LPUT4lad0Meta } from "/tmp/build_a1bfb712/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue?macro=true";
import { default as confirmDHQVOAqb7yMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue?macro=true";
import { default as calendarR933iBkp3iMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/calendar.vue?macro=true";
import { default as chat5VtvDHynZdMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/chat.vue?macro=true";
import { default as aboutNFGftBMrvkMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/community-groups/[group_id]/about.vue?macro=true";
import { default as feedeGnlgYqTRiMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/community-groups/[group_id]/feed.vue?macro=true";
import { default as membersibtMI8pLIhMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/community-groups/[group_id]/members.vue?macro=true";
import { default as _91group_id_93f1jZKWGcqLMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/community-groups/[group_id].vue?macro=true";
import { default as community_45profile_45edit4kZYoVFFn5Meta } from "/tmp/build_a1bfb712/pages/[studio_url]/community-profile-edit.vue?macro=true";
import { default as _91id_93UGrSGtWg6nMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/community-profile/[id].vue?macro=true";
import { default as community7ZPU5laLm8Meta } from "/tmp/build_a1bfb712/pages/[studio_url]/community.vue?macro=true";
import { default as _91uuid_93NUDqa7KoE8Meta } from "/tmp/build_a1bfb712/pages/[studio_url]/complete-registration/[uuid].vue?macro=true";
import { default as _91unique_id_93Rwn8PPJIuSMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/contracts/[unique_id].vue?macro=true";
import { default as dashboardQokrjrGxxHMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/dashboard.vue?macro=true";
import { default as indexa2EWPD57n0Meta } from "/tmp/build_a1bfb712/pages/[studio_url]/event/details/[event_id]/index.vue?macro=true";
import { default as checkoutqUM0bcwru3Meta } from "/tmp/build_a1bfb712/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue?macro=true";
import { default as select_45productTGGKs3kE3CMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/event/details/[event_id]/select-product.vue?macro=true";
import { default as eventsqyUNNzLQkZMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/events.vue?macro=true";
import { default as _91uuid_93BBMc0xA7TcMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/gifts/[uuid].vue?macro=true";
import { default as indexMgDc1Wz4DBMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/gifts/index.vue?macro=true";
import { default as _91invitation_id_939HIusEh7zuMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/gifts/invitation/[invitation_id].vue?macro=true";
import { default as _91product_id_93JP6CRd3A6iMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/gifts/product/[product_id].vue?macro=true";
import { default as indexNgOOOb0fPrMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/index.vue?macro=true";
import { default as _91invitation_unique_id_937xncfzYYdrMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/invitation/[invitation_unique_id].vue?macro=true";
import { default as joinxL3FdJXW7lMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/join.vue?macro=true";
import { default as login3p1AXrQdCsMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/login.vue?macro=true";
import { default as logoutrL30dnlwYvMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/logout.vue?macro=true";
import { default as _91meeting_type_slug_9337nuh5NZuXMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/meet/[meeting_type_slug].vue?macro=true";
import { default as _91meeting_unique_id_9321WvTZB4bHMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/meet/[meeting_unique_id].vue?macro=true";
import { default as _91meeting_unique_id_93JHWWlqUFjdMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/meeting/[meeting_unique_id].vue?macro=true";
import { default as my_45favorites3EdUmKlmLRMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/my-favorites.vue?macro=true";
import { default as my_45studiosywVCiiHVtfMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/my-studios.vue?macro=true";
import { default as optinUdTojYK6wVMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/optin.vue?macro=true";
import { default as _91episode_id_93NVQxKnyhikMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue?macro=true";
import { default as indexwqcI4nascaMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/podcasts/[podcast_id]/index.vue?macro=true";
import { default as indexjqGicrujZBMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/podcasts/index.vue?macro=true";
import { default as aboutp9tEqhEd2zMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/about.vue?macro=true";
import { default as digital_45download_45content4PygiF0DTCMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/digital-download-content.vue?macro=true";
import { default as events8M9Dz0aMv0Meta } from "/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93mwes8cConjMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45sampleqXfHLZ314EMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productspxl7XAlrfFMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93RU77scq5CmMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as viewD1zUAQI0hXMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexC5iqODQXvoMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93V9f1DcTlMvMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexXXb0IrRps6Meta } from "/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93MuRYlpzagPMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as indexqlJawTvPkeMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionsRy4NRAgwzTMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordingsXU8BMuVZ08Meta } from "/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsSFk9z4LcPiMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_935vOBZp3RVPMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id].vue?macro=true";
import { default as indexmOlFo05NfFMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/product/index.vue?macro=true";
import { default as indexOGUlsXnc92Meta } from "/tmp/build_a1bfb712/pages/[studio_url]/profile/index.vue?macro=true";
import { default as purchasesh2G8iJtSg6Meta } from "/tmp/build_a1bfb712/pages/[studio_url]/profile/purchases.vue?macro=true";
import { default as redemptionsTOYO7Myft5Meta } from "/tmp/build_a1bfb712/pages/[studio_url]/profile/redemptions.vue?macro=true";
import { default as _91quiz_id_93rkBJaJdl2kMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/quiz/[quiz_id].vue?macro=true";
import { default as _91token_93F6zcVkxjE2Meta } from "/tmp/build_a1bfb712/pages/[studio_url]/reset-password/[uid]/[token].vue?macro=true";
import { default as reset5PPR8gYCvfMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/reset.vue?macro=true";
import { default as shopq5O51haAdrMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/shop.vue?macro=true";
import { default as signup8Q3In7cQPFMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/signup.vue?macro=true";
import { default as liveQ8qJRb2vRFMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/streaming/[event_id]/live.vue?macro=true";
import { default as tipsMNuYnuU0NcMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/tips.vue?macro=true";
import { default as _91uuid_935lZ64kXCCeMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/waiver/[uuid].vue?macro=true";
import { default as compact3k8yEQn7dBMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/widgets/calendars/compact.vue?macro=true";
import { default as fulloUHT6IvAbGMeta } from "/tmp/build_a1bfb712/pages/[studio_url]/widgets/calendars/full.vue?macro=true";
export default [
  {
    name: _91product_id_93dvrjsDzOZGMeta?.name ?? "bundle-bundle_id-product-product_id",
    path: _91product_id_93dvrjsDzOZGMeta?.path ?? "/bundle/:bundle_id()/product/:product_id()",
    meta: _91product_id_93dvrjsDzOZGMeta || {},
    alias: _91product_id_93dvrjsDzOZGMeta?.alias || [],
    redirect: _91product_id_93dvrjsDzOZGMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutA1ISln1EmwMeta?.name ?? "bundle-bundle_id-product-product_id-about",
    path: aboutA1ISln1EmwMeta?.path ?? "about",
    meta: aboutA1ISln1EmwMeta || {},
    alias: aboutA1ISln1EmwMeta?.alias || [],
    redirect: aboutA1ISln1EmwMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: eventsjmjGLJavcHMeta?.name ?? "bundle-bundle_id-product-product_id-events",
    path: eventsjmjGLJavcHMeta?.path ?? "events",
    meta: eventsjmjGLJavcHMeta || {},
    alias: eventsjmjGLJavcHMeta?.alias || [],
    redirect: eventsjmjGLJavcHMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93OoAiASsvwlMeta?.name ?? "bundle-bundle_id-product-product_id-free-media-media_id",
    path: _91media_id_93OoAiASsvwlMeta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93OoAiASsvwlMeta || {},
    alias: _91media_id_93OoAiASsvwlMeta?.alias || [],
    redirect: _91media_id_93OoAiASsvwlMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45sampleLJX88mm4otMeta?.name ?? "bundle-bundle_id-product-product_id-free-sample",
    path: free_45sampleLJX88mm4otMeta?.path ?? "free-sample",
    meta: free_45sampleLJX88mm4otMeta || {},
    alias: free_45sampleLJX88mm4otMeta?.alias || [],
    redirect: free_45sampleLJX88mm4otMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productsSWxaEDUnhrMeta?.name ?? "bundle-bundle_id-product-product_id-included-products",
    path: included_45productsSWxaEDUnhrMeta?.path ?? "included-products",
    meta: included_45productsSWxaEDUnhrMeta || {},
    alias: included_45productsSWxaEDUnhrMeta?.alias || [],
    redirect: included_45productsSWxaEDUnhrMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93FoW068XwaeMeta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93FoW068XwaeMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93FoW068XwaeMeta || {},
    alias: _91media_id_93FoW068XwaeMeta?.alias || [],
    redirect: _91media_id_93FoW068XwaeMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: viewmIiwBRDne8Meta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-view",
    path: viewmIiwBRDne8Meta?.path ?? "lessons/:lesson_id()/view",
    meta: viewmIiwBRDne8Meta || {},
    alias: viewmIiwBRDne8Meta?.alias || [],
    redirect: viewmIiwBRDne8Meta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexu0INxbu4GoMeta?.name ?? "bundle-bundle_id-product-product_id-lessons",
    path: indexu0INxbu4GoMeta?.path ?? "lessons",
    meta: indexu0INxbu4GoMeta || {},
    alias: indexu0INxbu4GoMeta?.alias || [],
    redirect: indexu0INxbu4GoMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93XwsY95PRwxMeta?.name ?? "bundle-bundle_id-product-product_id-media-media_id",
    path: _91media_id_93XwsY95PRwxMeta?.path ?? "media/:media_id()",
    meta: _91media_id_93XwsY95PRwxMeta || {},
    alias: _91media_id_93XwsY95PRwxMeta?.alias || [],
    redirect: _91media_id_93XwsY95PRwxMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexo8BQAHzNAEMeta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id",
    path: indexo8BQAHzNAEMeta?.path ?? "playlists/:playlist_id()",
    meta: indexo8BQAHzNAEMeta || {},
    alias: indexo8BQAHzNAEMeta?.alias || [],
    redirect: indexo8BQAHzNAEMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_932XN80veQr2Meta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_932XN80veQr2Meta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_932XN80veQr2Meta || {},
    alias: _91media_id_932XN80veQr2Meta?.alias || [],
    redirect: _91media_id_932XN80veQr2Meta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexv6tJdTvMw4Meta?.name ?? "bundle-bundle_id-product-product_id-playlists",
    path: indexv6tJdTvMw4Meta?.path ?? "playlists",
    meta: indexv6tJdTvMw4Meta || {},
    alias: indexv6tJdTvMw4Meta?.alias || [],
    redirect: indexv6tJdTvMw4Meta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionsf3ZmN6pACdMeta?.name ?? "bundle-bundle_id-product-product_id-private-sessions",
    path: private_45sessionsf3ZmN6pACdMeta?.path ?? "private-sessions",
    meta: private_45sessionsf3ZmN6pACdMeta || {},
    alias: private_45sessionsf3ZmN6pACdMeta?.alias || [],
    redirect: private_45sessionsf3ZmN6pACdMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordingsGC3qaL8IgwMeta?.name ?? "bundle-bundle_id-product-product_id-recordings",
    path: recordingsGC3qaL8IgwMeta?.path ?? "recordings",
    meta: recordingsGC3qaL8IgwMeta || {},
    alias: recordingsGC3qaL8IgwMeta?.alias || [],
    redirect: recordingsGC3qaL8IgwMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsl7yVZt7yaUMeta?.name ?? "bundle-bundle_id-product-product_id-reviews",
    path: reviewsl7yVZt7yaUMeta?.path ?? "reviews",
    meta: reviewsl7yVZt7yaUMeta || {},
    alias: reviewsl7yVZt7yaUMeta?.alias || [],
    redirect: reviewsl7yVZt7yaUMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index6j3K4AKFUgMeta?.name ?? "buy-product-product_id",
    path: index6j3K4AKFUgMeta?.path ?? "/buy/product/:product_id()",
    meta: index6j3K4AKFUgMeta || {},
    alias: index6j3K4AKFUgMeta?.alias || [],
    redirect: index6j3K4AKFUgMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/buy/product/[product_id]/index.vue").then(m => m.default || m)
  },
  {
    name: confirm9LPUT4lad0Meta?.name ?? "buy-product-product_id-paypal-confirm",
    path: confirm9LPUT4lad0Meta?.path ?? "/buy/product/:product_id()/paypal/confirm",
    meta: confirm9LPUT4lad0Meta || {},
    alias: confirm9LPUT4lad0Meta?.alias || [],
    redirect: confirm9LPUT4lad0Meta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmDHQVOAqb7yMeta?.name ?? "buy-product-product_id-stripe-confirm",
    path: confirmDHQVOAqb7yMeta?.path ?? "/buy/product/:product_id()/stripe/confirm",
    meta: confirmDHQVOAqb7yMeta || {},
    alias: confirmDHQVOAqb7yMeta?.alias || [],
    redirect: confirmDHQVOAqb7yMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue").then(m => m.default || m)
  },
  {
    name: calendarR933iBkp3iMeta?.name ?? "calendar",
    path: calendarR933iBkp3iMeta?.path ?? "/calendar",
    meta: calendarR933iBkp3iMeta || {},
    alias: calendarR933iBkp3iMeta?.alias || [],
    redirect: calendarR933iBkp3iMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/calendar.vue").then(m => m.default || m)
  },
  {
    name: chat5VtvDHynZdMeta?.name ?? "chat",
    path: chat5VtvDHynZdMeta?.path ?? "/chat",
    meta: chat5VtvDHynZdMeta || {},
    alias: chat5VtvDHynZdMeta?.alias || [],
    redirect: chat5VtvDHynZdMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/chat.vue").then(m => m.default || m)
  },
  {
    name: _91group_id_93f1jZKWGcqLMeta?.name ?? "community-groups-group_id",
    path: _91group_id_93f1jZKWGcqLMeta?.path ?? "/community-groups/:group_id()",
    meta: _91group_id_93f1jZKWGcqLMeta || {},
    alias: _91group_id_93f1jZKWGcqLMeta?.alias || [],
    redirect: _91group_id_93f1jZKWGcqLMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/community-groups/[group_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutNFGftBMrvkMeta?.name ?? "community-groups-group_id-about",
    path: aboutNFGftBMrvkMeta?.path ?? "about",
    meta: aboutNFGftBMrvkMeta || {},
    alias: aboutNFGftBMrvkMeta?.alias || [],
    redirect: aboutNFGftBMrvkMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/community-groups/[group_id]/about.vue").then(m => m.default || m)
  },
  {
    name: feedeGnlgYqTRiMeta?.name ?? "community-groups-group_id-feed",
    path: feedeGnlgYqTRiMeta?.path ?? "feed",
    meta: feedeGnlgYqTRiMeta || {},
    alias: feedeGnlgYqTRiMeta?.alias || [],
    redirect: feedeGnlgYqTRiMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/community-groups/[group_id]/feed.vue").then(m => m.default || m)
  },
  {
    name: membersibtMI8pLIhMeta?.name ?? "community-groups-group_id-members",
    path: membersibtMI8pLIhMeta?.path ?? "members",
    meta: membersibtMI8pLIhMeta || {},
    alias: membersibtMI8pLIhMeta?.alias || [],
    redirect: membersibtMI8pLIhMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/community-groups/[group_id]/members.vue").then(m => m.default || m)
  }
]
  },
  {
    name: community_45profile_45edit4kZYoVFFn5Meta?.name ?? "community-profile-edit",
    path: community_45profile_45edit4kZYoVFFn5Meta?.path ?? "/community-profile-edit",
    meta: community_45profile_45edit4kZYoVFFn5Meta || {},
    alias: community_45profile_45edit4kZYoVFFn5Meta?.alias || [],
    redirect: community_45profile_45edit4kZYoVFFn5Meta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/community-profile-edit.vue").then(m => m.default || m)
  },
  {
    name: _91id_93UGrSGtWg6nMeta?.name ?? "community-profile-id",
    path: _91id_93UGrSGtWg6nMeta?.path ?? "/community-profile/:id()",
    meta: _91id_93UGrSGtWg6nMeta || {},
    alias: _91id_93UGrSGtWg6nMeta?.alias || [],
    redirect: _91id_93UGrSGtWg6nMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/community-profile/[id].vue").then(m => m.default || m)
  },
  {
    name: community7ZPU5laLm8Meta?.name ?? "community",
    path: community7ZPU5laLm8Meta?.path ?? "/community",
    meta: community7ZPU5laLm8Meta || {},
    alias: community7ZPU5laLm8Meta?.alias || [],
    redirect: community7ZPU5laLm8Meta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/community.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93NUDqa7KoE8Meta?.name ?? "complete-registration-uuid",
    path: _91uuid_93NUDqa7KoE8Meta?.path ?? "/complete-registration/:uuid()",
    meta: _91uuid_93NUDqa7KoE8Meta || {},
    alias: _91uuid_93NUDqa7KoE8Meta?.alias || [],
    redirect: _91uuid_93NUDqa7KoE8Meta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/complete-registration/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91unique_id_93Rwn8PPJIuSMeta?.name ?? "contracts-unique_id",
    path: _91unique_id_93Rwn8PPJIuSMeta?.path ?? "/contracts/:unique_id()",
    meta: _91unique_id_93Rwn8PPJIuSMeta || {},
    alias: _91unique_id_93Rwn8PPJIuSMeta?.alias || [],
    redirect: _91unique_id_93Rwn8PPJIuSMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/contracts/[unique_id].vue").then(m => m.default || m)
  },
  {
    name: dashboardQokrjrGxxHMeta?.name ?? "dashboard",
    path: dashboardQokrjrGxxHMeta?.path ?? "/dashboard",
    meta: dashboardQokrjrGxxHMeta || {},
    alias: dashboardQokrjrGxxHMeta?.alias || [],
    redirect: dashboardQokrjrGxxHMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexa2EWPD57n0Meta?.name ?? "event-details-event_id",
    path: indexa2EWPD57n0Meta?.path ?? "/event/details/:event_id()",
    meta: indexa2EWPD57n0Meta || {},
    alias: indexa2EWPD57n0Meta?.alias || [],
    redirect: indexa2EWPD57n0Meta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/event/details/[event_id]/index.vue").then(m => m.default || m)
  },
  {
    name: checkoutqUM0bcwru3Meta?.name ?? "event-details-event_id-product-product_id-checkout",
    path: checkoutqUM0bcwru3Meta?.path ?? "/event/details/:event_id()/product/:product_id()/checkout",
    meta: checkoutqUM0bcwru3Meta || {},
    alias: checkoutqUM0bcwru3Meta?.alias || [],
    redirect: checkoutqUM0bcwru3Meta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue").then(m => m.default || m)
  },
  {
    name: select_45productTGGKs3kE3CMeta?.name ?? "event-details-event_id-select-product",
    path: select_45productTGGKs3kE3CMeta?.path ?? "/event/details/:event_id()/select-product",
    meta: select_45productTGGKs3kE3CMeta || {},
    alias: select_45productTGGKs3kE3CMeta?.alias || [],
    redirect: select_45productTGGKs3kE3CMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/event/details/[event_id]/select-product.vue").then(m => m.default || m)
  },
  {
    name: eventsqyUNNzLQkZMeta?.name ?? "events",
    path: eventsqyUNNzLQkZMeta?.path ?? "/events",
    meta: eventsqyUNNzLQkZMeta || {},
    alias: eventsqyUNNzLQkZMeta?.alias || [],
    redirect: eventsqyUNNzLQkZMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/events.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93BBMc0xA7TcMeta?.name ?? "gifts-uuid",
    path: _91uuid_93BBMc0xA7TcMeta?.path ?? "/gifts/:uuid()",
    meta: _91uuid_93BBMc0xA7TcMeta || {},
    alias: _91uuid_93BBMc0xA7TcMeta?.alias || [],
    redirect: _91uuid_93BBMc0xA7TcMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/gifts/[uuid].vue").then(m => m.default || m)
  },
  {
    name: indexMgDc1Wz4DBMeta?.name ?? "gifts",
    path: indexMgDc1Wz4DBMeta?.path ?? "/gifts",
    meta: indexMgDc1Wz4DBMeta || {},
    alias: indexMgDc1Wz4DBMeta?.alias || [],
    redirect: indexMgDc1Wz4DBMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/gifts/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_id_939HIusEh7zuMeta?.name ?? "gifts-invitation-invitation_id",
    path: _91invitation_id_939HIusEh7zuMeta?.path ?? "/gifts/invitation/:invitation_id()",
    meta: _91invitation_id_939HIusEh7zuMeta || {},
    alias: _91invitation_id_939HIusEh7zuMeta?.alias || [],
    redirect: _91invitation_id_939HIusEh7zuMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/gifts/invitation/[invitation_id].vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93JP6CRd3A6iMeta?.name ?? "gifts-product-product_id",
    path: _91product_id_93JP6CRd3A6iMeta?.path ?? "/gifts/product/:product_id()",
    meta: _91product_id_93JP6CRd3A6iMeta || {},
    alias: _91product_id_93JP6CRd3A6iMeta?.alias || [],
    redirect: _91product_id_93JP6CRd3A6iMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/gifts/product/[product_id].vue").then(m => m.default || m)
  },
  {
    name: indexNgOOOb0fPrMeta?.name ?? "index",
    path: indexNgOOOb0fPrMeta?.path ?? "/",
    meta: indexNgOOOb0fPrMeta || {},
    alias: indexNgOOOb0fPrMeta?.alias || [],
    redirect: indexNgOOOb0fPrMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_unique_id_937xncfzYYdrMeta?.name ?? "invitation-invitation_unique_id",
    path: _91invitation_unique_id_937xncfzYYdrMeta?.path ?? "/invitation/:invitation_unique_id()",
    meta: _91invitation_unique_id_937xncfzYYdrMeta || {},
    alias: _91invitation_unique_id_937xncfzYYdrMeta?.alias || [],
    redirect: _91invitation_unique_id_937xncfzYYdrMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/invitation/[invitation_unique_id].vue").then(m => m.default || m)
  },
  {
    name: joinxL3FdJXW7lMeta?.name ?? "join",
    path: joinxL3FdJXW7lMeta?.path ?? "/join",
    meta: joinxL3FdJXW7lMeta || {},
    alias: joinxL3FdJXW7lMeta?.alias || [],
    redirect: joinxL3FdJXW7lMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/join.vue").then(m => m.default || m)
  },
  {
    name: login3p1AXrQdCsMeta?.name ?? "login",
    path: login3p1AXrQdCsMeta?.path ?? "/login",
    meta: login3p1AXrQdCsMeta || {},
    alias: login3p1AXrQdCsMeta?.alias || [],
    redirect: login3p1AXrQdCsMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/login.vue").then(m => m.default || m)
  },
  {
    name: logoutrL30dnlwYvMeta?.name ?? "logout",
    path: logoutrL30dnlwYvMeta?.path ?? "/logout",
    meta: logoutrL30dnlwYvMeta || {},
    alias: logoutrL30dnlwYvMeta?.alias || [],
    redirect: logoutrL30dnlwYvMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/logout.vue").then(m => m.default || m)
  },
  {
    name: _91meeting_type_slug_9337nuh5NZuXMeta?.name ?? "meet-meeting_type_slug",
    path: _91meeting_type_slug_9337nuh5NZuXMeta?.path ?? "/meet/:meeting_type_slug()",
    meta: _91meeting_type_slug_9337nuh5NZuXMeta || {},
    alias: _91meeting_type_slug_9337nuh5NZuXMeta?.alias || [],
    redirect: _91meeting_type_slug_9337nuh5NZuXMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/meet/[meeting_type_slug].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_9321WvTZB4bHMeta?.name ?? "meet-meeting_unique_id",
    path: _91meeting_unique_id_9321WvTZB4bHMeta?.path ?? "/meet/:meeting_unique_id()",
    meta: _91meeting_unique_id_9321WvTZB4bHMeta || {},
    alias: _91meeting_unique_id_9321WvTZB4bHMeta?.alias || [],
    redirect: _91meeting_unique_id_9321WvTZB4bHMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/meet/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_93JHWWlqUFjdMeta?.name ?? "meeting-meeting_unique_id",
    path: _91meeting_unique_id_93JHWWlqUFjdMeta?.path ?? "/meeting/:meeting_unique_id()",
    meta: _91meeting_unique_id_93JHWWlqUFjdMeta || {},
    alias: _91meeting_unique_id_93JHWWlqUFjdMeta?.alias || [],
    redirect: _91meeting_unique_id_93JHWWlqUFjdMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/meeting/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: my_45favorites3EdUmKlmLRMeta?.name ?? "my-favorites",
    path: my_45favorites3EdUmKlmLRMeta?.path ?? "/my-favorites",
    meta: my_45favorites3EdUmKlmLRMeta || {},
    alias: my_45favorites3EdUmKlmLRMeta?.alias || [],
    redirect: my_45favorites3EdUmKlmLRMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: my_45studiosywVCiiHVtfMeta?.name ?? "my-studios",
    path: my_45studiosywVCiiHVtfMeta?.path ?? "/my-studios",
    meta: my_45studiosywVCiiHVtfMeta || {},
    alias: my_45studiosywVCiiHVtfMeta?.alias || [],
    redirect: my_45studiosywVCiiHVtfMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/my-studios.vue").then(m => m.default || m)
  },
  {
    name: optinUdTojYK6wVMeta?.name ?? "optin",
    path: optinUdTojYK6wVMeta?.path ?? "/optin",
    meta: optinUdTojYK6wVMeta || {},
    alias: optinUdTojYK6wVMeta?.alias || [],
    redirect: optinUdTojYK6wVMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/optin.vue").then(m => m.default || m)
  },
  {
    name: _91episode_id_93NVQxKnyhikMeta?.name ?? "podcasts-podcast_id-episodes-episode_id",
    path: _91episode_id_93NVQxKnyhikMeta?.path ?? "/podcasts/:podcast_id()/episodes/:episode_id()",
    meta: _91episode_id_93NVQxKnyhikMeta || {},
    alias: _91episode_id_93NVQxKnyhikMeta?.alias || [],
    redirect: _91episode_id_93NVQxKnyhikMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue").then(m => m.default || m)
  },
  {
    name: indexwqcI4nascaMeta?.name ?? "podcasts-podcast_id",
    path: indexwqcI4nascaMeta?.path ?? "/podcasts/:podcast_id()",
    meta: indexwqcI4nascaMeta || {},
    alias: indexwqcI4nascaMeta?.alias || [],
    redirect: indexwqcI4nascaMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/podcasts/[podcast_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjqGicrujZBMeta?.name ?? "podcasts",
    path: indexjqGicrujZBMeta?.path ?? "/podcasts",
    meta: indexjqGicrujZBMeta || {},
    alias: indexjqGicrujZBMeta?.alias || [],
    redirect: indexjqGicrujZBMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/podcasts/index.vue").then(m => m.default || m)
  },
  {
    name: _91product_id_935vOBZp3RVPMeta?.name ?? "product-product_id",
    path: _91product_id_935vOBZp3RVPMeta?.path ?? "/product/:product_id()",
    meta: _91product_id_935vOBZp3RVPMeta || {},
    alias: _91product_id_935vOBZp3RVPMeta?.alias || [],
    redirect: _91product_id_935vOBZp3RVPMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutp9tEqhEd2zMeta?.name ?? "product-product_id-about",
    path: aboutp9tEqhEd2zMeta?.path ?? "about",
    meta: aboutp9tEqhEd2zMeta || {},
    alias: aboutp9tEqhEd2zMeta?.alias || [],
    redirect: aboutp9tEqhEd2zMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: digital_45download_45content4PygiF0DTCMeta?.name ?? "product-product_id-digital-download-content",
    path: digital_45download_45content4PygiF0DTCMeta?.path ?? "digital-download-content",
    meta: digital_45download_45content4PygiF0DTCMeta || {},
    alias: digital_45download_45content4PygiF0DTCMeta?.alias || [],
    redirect: digital_45download_45content4PygiF0DTCMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/digital-download-content.vue").then(m => m.default || m)
  },
  {
    name: events8M9Dz0aMv0Meta?.name ?? "product-product_id-events",
    path: events8M9Dz0aMv0Meta?.path ?? "events",
    meta: events8M9Dz0aMv0Meta || {},
    alias: events8M9Dz0aMv0Meta?.alias || [],
    redirect: events8M9Dz0aMv0Meta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93mwes8cConjMeta?.name ?? "product-product_id-free-media-media_id",
    path: _91media_id_93mwes8cConjMeta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93mwes8cConjMeta || {},
    alias: _91media_id_93mwes8cConjMeta?.alias || [],
    redirect: _91media_id_93mwes8cConjMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45sampleqXfHLZ314EMeta?.name ?? "product-product_id-free-sample",
    path: free_45sampleqXfHLZ314EMeta?.path ?? "free-sample",
    meta: free_45sampleqXfHLZ314EMeta || {},
    alias: free_45sampleqXfHLZ314EMeta?.alias || [],
    redirect: free_45sampleqXfHLZ314EMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productspxl7XAlrfFMeta?.name ?? "product-product_id-included-products",
    path: included_45productspxl7XAlrfFMeta?.path ?? "included-products",
    meta: included_45productspxl7XAlrfFMeta || {},
    alias: included_45productspxl7XAlrfFMeta?.alias || [],
    redirect: included_45productspxl7XAlrfFMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93RU77scq5CmMeta?.name ?? "product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93RU77scq5CmMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93RU77scq5CmMeta || {},
    alias: _91media_id_93RU77scq5CmMeta?.alias || [],
    redirect: _91media_id_93RU77scq5CmMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: viewD1zUAQI0hXMeta?.name ?? "product-product_id-lessons-lesson_id-view",
    path: viewD1zUAQI0hXMeta?.path ?? "lessons/:lesson_id()/view",
    meta: viewD1zUAQI0hXMeta || {},
    alias: viewD1zUAQI0hXMeta?.alias || [],
    redirect: viewD1zUAQI0hXMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexC5iqODQXvoMeta?.name ?? "product-product_id-lessons",
    path: indexC5iqODQXvoMeta?.path ?? "lessons",
    meta: indexC5iqODQXvoMeta || {},
    alias: indexC5iqODQXvoMeta?.alias || [],
    redirect: indexC5iqODQXvoMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93V9f1DcTlMvMeta?.name ?? "product-product_id-media-media_id",
    path: _91media_id_93V9f1DcTlMvMeta?.path ?? "media/:media_id()",
    meta: _91media_id_93V9f1DcTlMvMeta || {},
    alias: _91media_id_93V9f1DcTlMvMeta?.alias || [],
    redirect: _91media_id_93V9f1DcTlMvMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexXXb0IrRps6Meta?.name ?? "product-product_id-playlists-playlist_id",
    path: indexXXb0IrRps6Meta?.path ?? "playlists/:playlist_id()",
    meta: indexXXb0IrRps6Meta || {},
    alias: indexXXb0IrRps6Meta?.alias || [],
    redirect: indexXXb0IrRps6Meta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93MuRYlpzagPMeta?.name ?? "product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93MuRYlpzagPMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93MuRYlpzagPMeta || {},
    alias: _91media_id_93MuRYlpzagPMeta?.alias || [],
    redirect: _91media_id_93MuRYlpzagPMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexqlJawTvPkeMeta?.name ?? "product-product_id-playlists",
    path: indexqlJawTvPkeMeta?.path ?? "playlists",
    meta: indexqlJawTvPkeMeta || {},
    alias: indexqlJawTvPkeMeta?.alias || [],
    redirect: indexqlJawTvPkeMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionsRy4NRAgwzTMeta?.name ?? "product-product_id-private-sessions",
    path: private_45sessionsRy4NRAgwzTMeta?.path ?? "private-sessions",
    meta: private_45sessionsRy4NRAgwzTMeta || {},
    alias: private_45sessionsRy4NRAgwzTMeta?.alias || [],
    redirect: private_45sessionsRy4NRAgwzTMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordingsXU8BMuVZ08Meta?.name ?? "product-product_id-recordings",
    path: recordingsXU8BMuVZ08Meta?.path ?? "recordings",
    meta: recordingsXU8BMuVZ08Meta || {},
    alias: recordingsXU8BMuVZ08Meta?.alias || [],
    redirect: recordingsXU8BMuVZ08Meta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsSFk9z4LcPiMeta?.name ?? "product-product_id-reviews",
    path: reviewsSFk9z4LcPiMeta?.path ?? "reviews",
    meta: reviewsSFk9z4LcPiMeta || {},
    alias: reviewsSFk9z4LcPiMeta?.alias || [],
    redirect: reviewsSFk9z4LcPiMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexmOlFo05NfFMeta?.name ?? "product",
    path: indexmOlFo05NfFMeta?.path ?? "/product",
    meta: indexmOlFo05NfFMeta || {},
    alias: indexmOlFo05NfFMeta?.alias || [],
    redirect: indexmOlFo05NfFMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/product/index.vue").then(m => m.default || m)
  },
  {
    name: indexOGUlsXnc92Meta?.name ?? "profile",
    path: indexOGUlsXnc92Meta?.path ?? "/profile",
    meta: indexOGUlsXnc92Meta || {},
    alias: indexOGUlsXnc92Meta?.alias || [],
    redirect: indexOGUlsXnc92Meta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/profile/index.vue").then(m => m.default || m)
  },
  {
    name: purchasesh2G8iJtSg6Meta?.name ?? "profile-purchases",
    path: purchasesh2G8iJtSg6Meta?.path ?? "/profile/purchases",
    meta: purchasesh2G8iJtSg6Meta || {},
    alias: purchasesh2G8iJtSg6Meta?.alias || [],
    redirect: purchasesh2G8iJtSg6Meta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/profile/purchases.vue").then(m => m.default || m)
  },
  {
    name: redemptionsTOYO7Myft5Meta?.name ?? "profile-redemptions",
    path: redemptionsTOYO7Myft5Meta?.path ?? "/profile/redemptions",
    meta: redemptionsTOYO7Myft5Meta || {},
    alias: redemptionsTOYO7Myft5Meta?.alias || [],
    redirect: redemptionsTOYO7Myft5Meta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/profile/redemptions.vue").then(m => m.default || m)
  },
  {
    name: _91quiz_id_93rkBJaJdl2kMeta?.name ?? "quiz-quiz_id",
    path: _91quiz_id_93rkBJaJdl2kMeta?.path ?? "/quiz/:quiz_id()",
    meta: _91quiz_id_93rkBJaJdl2kMeta || {},
    alias: _91quiz_id_93rkBJaJdl2kMeta?.alias || [],
    redirect: _91quiz_id_93rkBJaJdl2kMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/quiz/[quiz_id].vue").then(m => m.default || m)
  },
  {
    name: _91token_93F6zcVkxjE2Meta?.name ?? "reset-password-uid-token",
    path: _91token_93F6zcVkxjE2Meta?.path ?? "/reset-password/:uid()/:token()",
    meta: _91token_93F6zcVkxjE2Meta || {},
    alias: _91token_93F6zcVkxjE2Meta?.alias || [],
    redirect: _91token_93F6zcVkxjE2Meta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/reset-password/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: reset5PPR8gYCvfMeta?.name ?? "reset",
    path: reset5PPR8gYCvfMeta?.path ?? "/reset",
    meta: reset5PPR8gYCvfMeta || {},
    alias: reset5PPR8gYCvfMeta?.alias || [],
    redirect: reset5PPR8gYCvfMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/reset.vue").then(m => m.default || m)
  },
  {
    name: shopq5O51haAdrMeta?.name ?? "shop",
    path: shopq5O51haAdrMeta?.path ?? "/shop",
    meta: shopq5O51haAdrMeta || {},
    alias: shopq5O51haAdrMeta?.alias || [],
    redirect: shopq5O51haAdrMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/shop.vue").then(m => m.default || m)
  },
  {
    name: signup8Q3In7cQPFMeta?.name ?? "signup",
    path: signup8Q3In7cQPFMeta?.path ?? "/signup",
    meta: signup8Q3In7cQPFMeta || {},
    alias: signup8Q3In7cQPFMeta?.alias || [],
    redirect: signup8Q3In7cQPFMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/signup.vue").then(m => m.default || m)
  },
  {
    name: liveQ8qJRb2vRFMeta?.name ?? "streaming-event_id-live",
    path: liveQ8qJRb2vRFMeta?.path ?? "/streaming/:event_id()/live",
    meta: liveQ8qJRb2vRFMeta || {},
    alias: liveQ8qJRb2vRFMeta?.alias || [],
    redirect: liveQ8qJRb2vRFMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/streaming/[event_id]/live.vue").then(m => m.default || m)
  },
  {
    name: tipsMNuYnuU0NcMeta?.name ?? "tips",
    path: tipsMNuYnuU0NcMeta?.path ?? "/tips",
    meta: tipsMNuYnuU0NcMeta || {},
    alias: tipsMNuYnuU0NcMeta?.alias || [],
    redirect: tipsMNuYnuU0NcMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/tips.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_935lZ64kXCCeMeta?.name ?? "waiver-uuid",
    path: _91uuid_935lZ64kXCCeMeta?.path ?? "/waiver/:uuid()",
    meta: _91uuid_935lZ64kXCCeMeta || {},
    alias: _91uuid_935lZ64kXCCeMeta?.alias || [],
    redirect: _91uuid_935lZ64kXCCeMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/waiver/[uuid].vue").then(m => m.default || m)
  },
  {
    name: compact3k8yEQn7dBMeta?.name ?? "widgets-calendars-compact",
    path: compact3k8yEQn7dBMeta?.path ?? "/widgets/calendars/compact",
    meta: compact3k8yEQn7dBMeta || {},
    alias: compact3k8yEQn7dBMeta?.alias || [],
    redirect: compact3k8yEQn7dBMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/widgets/calendars/compact.vue").then(m => m.default || m)
  },
  {
    name: fulloUHT6IvAbGMeta?.name ?? "widgets-calendars-full",
    path: fulloUHT6IvAbGMeta?.path ?? "/widgets/calendars/full",
    meta: fulloUHT6IvAbGMeta || {},
    alias: fulloUHT6IvAbGMeta?.alias || [],
    redirect: fulloUHT6IvAbGMeta?.redirect,
    component: () => import("/tmp/build_a1bfb712/pages/[studio_url]/widgets/calendars/full.vue").then(m => m.default || m)
  }
]