<template>
  <div
    v-if="
        !$route.meta.hideInstagramSlider && instagramFilteredPhotos.length > 0
      "
  >
    <hr class="my-4" />
    <swiper-container
      class="swiper"
      effect="coverflow"
      :slidesPerView="5"
      :spaceBetween="12"
    >
      <swiper-slide
        v-for="photo in instagramFilteredPhotos"
        :key="photo.media_url"
      >
        <a :href="photo.permalink" target="_blank">
          <img :src="photo.media_url" class="instagram-image" />
        </a>
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<script>
import { useStudioStore } from '@/stores';

export default {
  name: 'InstagramPhotosSlider',
  props: {
    studioId: {
      type: Number,
    },
  },
  computed: {
    instagramFilteredPhotos() {
      return this.instagramPhotos
        .filter(
          photo =>
            photo.media_type == 'IMAGE' || photo.media_type == 'CAROUSEL_ALBUM'
        )
        .splice(0, 10);
    },
  },
  data() {
    return {
      instagramPhotos: [],
    };
  },
  watch: {
    studioId: {
      async handler(studioId) {
        if (studioId) {
          try {
            this.instagramPhotos = await useStudioStore().getInstagramPhotos(studioId);

            // TODO: Add this back 
            // this.swiperOption.initialSlide =
            //   this.instagramFilteredPhotos.length / 2;
          } catch (error) {
            console.log(error);
          }
        } else {
          this.instagramPhotos = [];
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.instagram-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.studio-instagram {
  width: 100%;
  height: 400px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    background-color: transparent;
    background-position: center;
    background-size: cover;
    color: $white;
    margin: 0 6px;
    border-radius: 6px;
  }

  .swiper-container {
    border-radius: 6px;
  }

  .swiper-pagination {
    display: none;

    :deep(.swiper-pagination-bullet.swiper-pagination-bullet-active) {
      background-color: $dark-gray;
      color: $light-gray;
    }
  }
}
</style>
