import apiAuth from '../api/auth';
import { defineStore } from 'pinia'
import { hasOwn, trim } from '@/utils/utils';
import { useStudioStore } from './studio';
import { useCommunityStore } from './community';
import { useGlobalsStore } from './globals';
import { useSettingsStore } from './settings';
import Cookies from 'js-cookie';

interface State {
  role: string | null;
  localStudioPassword: string | null;
  user: UserResponse | null;
  teacher: TeacherResponse | null;
  userInfoPromise: Promise<UsersInfoResponse> | null;
  plans: PlanResponse | null;
  programme: CoachingProgrammeResponse | null;
  coupon: string | null;
  couponPlans: PlanResponse | null;
  couponDuration: CouponDurationResponse | null;
  campaign: string | null;
  zoomInfo: ZoomInfoResponse[] | null;
  currency: string;
  subscriptions: StudentSubscriptionResponse[] | null;
  punches: StudentPunchesResponse[] | any[];
  purchases: StudentPurchasesResponse[] | any[];
  usersAndInvitations: UsersAndInvitationsResponse | null;
  teacherPermissions: TeacherPermissionsResponse | {};
  showUpgradeModal: boolean;
}

export const useUserStore = defineStore('user', {
  state: ():State => {
    return {
      localStudioPassword: null,
      role: null,
      user: null,
      teacher: null,
      userInfoPromise: null,
      plans: null,
      programme: null,
      coupon: null,
      couponDuration: null,
      couponPlans: null,
      campaign: null,
      zoomInfo: null,
      currency: 'USD',
      subscriptions: null,
      punches: [],
      purchases: [],
      usersAndInvitations: null,
      teacherPermissions: {},
      showUpgradeModal: false,
    };
  },
  getters: {
    accessToken: state => {
      return useCookie('accessToken');
    },
    canAccessLockedPages: state => {
      if (!state.teacher?.teacher) return;
      const { studio_password, enable_studio_password } = state.teacher.teacher;
      return (
        !enable_studio_password ||
        studio_password === btoa(state.localStudioPassword || '')
      );
    },
    isLoggedIn: state => {
      return !!state.accessToken.value;
    },
    userId: state => state.user?.user_id,
    userHash: state => state.user?.user_hash,
    studentId: state => state.user?.student?.id,
    student: state => state.user?.student,
    studentPhone: state => state.user?.student?.phone,
    studentCommunityProfileId: state => state.user?.community_profile_id,
    studentCommunityProfileFirstName: state => state.user?.community_first_name,
    studentCommunityProfileLasttName: state => state.user?.community_last_name,
    studioCommunityProfileId: state =>
      state.teacher?.teacher?.community_profile_id,
    userFullName: state =>
      trim(state.user?.user_full_name) ||
      `${state.teacher?.teacher?.first_name} ${state.teacher?.teacher?.last_name}`,
    userPlans: ({ plans, coupon, couponPlans }) =>
      (coupon && couponPlans) || plans || [],
    studio: state => state.teacher?.teacher,
    teacherMagicCode: state => state.teacher?.magic_code,
    studentMagicCode: state => state.user?.magic_code,
    isStudioOwner: state => state.teacher?.is_studio_owner,
    studioURL: state => state.teacher?.teacher?.studio_url,
    maxTeamSize: state => state.teacher?.teacher?.team_size,
    maxAdmins: state => state.teacher?.teacher?.max_admins,
    maxInstructors: state => state.teacher?.teacher?.max_instructors,
    instagramConnected: state => state.teacher?.teacher?.instagram_connected,
    teacherInviteEmailsQuota: state =>
      state.teacher?.teacher?.invite_emails_quota,
    isZoomConnected: state => state.teacher?.teacher?.is_zoom_connected,
    isTokboxEnabled: state => state.teacher?.teacher?.is_tokbox_enabled,
    isExternalAffiliate: state => state.teacher?.teacher?.is_external_affiliate,
    isBioAccount: state => state.teacher?.teacher?.is_bio,
    zoomWebinarsEnabled: state => state.teacher?.teacher?.zoom_webinars_enabled,
    teacherStudioUrl: state => state.teacher?.teacher.studio_url,
    studentsLastStudioUrl: state => state.user?.studio_url,
    showProductCustomFields: state =>
      state.teacher?.teacher?.zapier_custom_fields_enabled,
    numberOfThisStudioPunches: state =>
      state.punches.filter(
        p =>
          p.studio_url === useStudioStore().currentStudioUrl && !p.expired
      ).length,
    language: state => {
      return state.teacher?.language;
    },
    hasMonthlyPlans(state) {
      return state.userPlans.reduce(
        (sum, { monthly_amount }) => sum + parseInt(monthly_amount),
        0
      );
    },
    hasYearlyPlans(state) {
      return state.userPlans.reduce(
        (sum, { yearly_amount }) => sum + parseInt(yearly_amount),
        0
      );
    },
    hasSubscriptionType(state) {
      return !!(getters.hasMonthlyPlans && getters.hasYearlyPlans);
    },
    hasMultiPlans(state) {
      return state.userPlans.length > 1;
    },
    currentStudioSubscriptions( state): StudentSubscriptionResponse[] | null {
      const currentStudioUrl = useStudioStore().currentStudioUrl;
      if (currentStudioUrl && state.subscriptions) {
        return state.subscriptions?.filter(
          ({ studio_url }) => studio_url === currentStudioUrl
        );
      } else return null;
    },
    customDomainEmails: state =>
      state.teacher?.teacher?.custom_domain_email_links_enabled,
    customDomainWidgets: state =>
      state.teacher?.teacher?.custom_domain_widgets_enabled,
    timezone: state => state.user?.timezone,
  },
  actions: {
    async setAccessToken(token: string|null) {
      // this.accessToken = token;
      // await this.$persist();
      const accessToken = useCookie('accessToken', {
        // expires in 1 year
        maxAge: 60 * 60 * 24 * 365,
      });
      accessToken.value = token;
      refreshCookie('accessToken');
      if(process.client) {
        if (token) {
          Cookies.set('accessToken', token, { expires: 365 });
        } else {
          Cookies.remove('accessToken');
        }
      }
    },
    async logout() {
      this.resetState();
      this.userInfoPromise = null;
      await this.setAccessToken('');
      await this.loadUserInfo();
      useCommunityStore().disconnectChatClient()
    },
    async register(
      data: RegisterStudentRequest
    ): Promise<LoginResponse> {
      const loginResponse = await apiAuth.register(data);
      const { key } = loginResponse;
      await this.setAccessToken(key);
      await this.loadUserInfo();
      return loginResponse;
    },
    async login(
      { email, password }:{email: string, password:string}
    ): Promise<LoginResponse> {
      const loginResponse = await apiAuth.login(email, password);
      const { key } = loginResponse;
      await this.setAccessToken(key);
      await this.loadUserInfo();
      return loginResponse;
    },
    async loginWithFacebook(
      token: string
    ): Promise<LoginResponse> {
      const loginResponse = await apiAuth.facebookLogin(token);
      const { key } = loginResponse;
      await this.setAccessToken(key);
      await this.loadUserInfo();
      return loginResponse;
    },
    async loginWithFacebookCustom(
      { token, uid, email }: { token: string; uid: string; email: string }
    ): Promise<void> {
      const { key } = await apiAuth.facebookCustomLogin(token, uid, email);
      await this.setAccessToken(key);
      await this.loadUserInfo();
    },
    async loginWithMagicCode(
      magicCode: string
    ): Promise<LoginResponse> {
      const loginResponse = await apiAuth.magicCodeLogin(magicCode);      
      return loginResponse;
    },
    async loginWithGoogle(
      token: string
    ): Promise<LoginResponse> {
      const loginResponse = await apiAuth.googleLogin(token);
      const { key } = loginResponse;
      await this.setAccessToken(key);
      await this.loadUserInfo();
      return loginResponse;
    },
    async loginWithGoogleCustom(
      { token, uid, email }: { token: string; uid: string; email: string }
    ): Promise<void> {
      const { key } = await apiAuth.googleCustomLogin(token, uid, email);
      await this.setAccessToken(key);
      await this.loadUserInfo();
    },
    async loginWithApple(
      { uid, email, fullName, idToken }: { uid: string; email: string; fullName: string; idToken: string }
    ): Promise<void> {
      const { key } = await apiAuth.appleLogin(uid, email, fullName, idToken);
      await this.setAccessToken(key);
      await this.loadUserInfo();
    },
    async changePassword(password: ChangePassword): Promise<any> {
      const result = await apiAuth.changePassword(
        password.newPassword1,
        password.newPassword2
      );
      return result;
    },
    async resetPassword(
      { email, studioURL }: { email: string; studioURL: string }
    ): Promise<any> {
      const res = await apiAuth.resetPassword(email, studioURL);
      return res;
    },
    resetState(): void {
      this.$reset();
      useCommunityStore().$reset();
      useSettingsStore().$reset();
    },
    loadUserInfo() {
      const promise = apiAuth.loadUserInfo();
      promise.then(payload => {
        this.role = payload.role;
        this.user = payload.student;
        this.teacher = payload.teacher;
        this.zoomInfo = payload.zoom_info;
        this.teacherPermissions = payload.teacher_permissions;
        this.zoomInfo = payload.zoom_info;
      });
      // this.userInfoPromise = promise;
      return promise;
    },
    async reloadUserCommunityProfileAndNotifications() {
      if (this.isLoggedIn) {
        await useCommunityStore().loadStudentCommunityProfileData();

        this.studentPunches();
        useCommunityStore().getNotificationsToken();
        useCommunityStore().loadNotifications({ markSeen: false });

        if (this.role === 'teacher') {
          useCommunityStore().getStudioNotificationsToken();
          useCommunityStore().loadStudioNotifications({ markSeen: false });
        }
      } else {
        useCommunityStore().clearStudentCommunityProfileData();
      }
    },
    getLoginRedirectData(studioUrl: string | null) {
      if (this.role === 'student' || studioUrl) {
        const name = 'studio-dashboard';
        const params = { studio_url: studioUrl || this.user?.studio_url };
        return { name, params };
      } else {
        const name = 'dashboard';
        return { name };
      }
    },
    async meStudent() {
      const student = await apiAuth.meStudent();
      this.user = student;
    },
    async meTeacher() {
      const teacher = await apiAuth.meTeacher();
      this.teacher = teacher.teacher;
      return teacher;
    },
    async getStudioUsersAndInvitations() {
      const studioUsersAndInvitations = await apiAuth.getStudioUsersAndInvitations();
      this.usersAndInvitations = studioUsersAndInvitations;
      return studioUsersAndInvitations;
    },
    async updateStudent(user: UserResponse) {
      const student = await apiAuth.updateStudent(user);
      this.user = student;
    },
    async updateStudentDirect(user: UserResponse) {
      await apiAuth.updateStudent(user);
    },
    async toggleStudentEventNotifications(toggle: boolean) {
      const student = await apiAuth.toggleStudentEventNotifications(toggle);
      this.user = student;
    },
    async toggleStudentEmailNotifications(toggle: boolean) {
      const student = await apiAuth.toggleStudentEmailNotifications(toggle);
      this.user = student;
    },
    async toggleStudentSMSNotifications(toggle: boolean) {
      const student = await apiAuth.toggleStudentSMSNotifications(toggle);
      this.user = student;
    },
    async toggleStudentEmail30mins(toggle: boolean) {
      const student = await apiAuth.toggleStudentEmail30mins(toggle);
      this.user = student;
    },
    async toggleStudentEmail1hr(toggle: boolean) {
      const student = await apiAuth.toggleStudentEmail1hr(toggle);
      this.user = student;
    },
    async toggleCommunityDailyDigestEmail(toggle: boolean) {
      const student = await apiAuth.toggleCommunityDailyDigestEmail(toggle);
      this.user = student;
    },
    async toggleCommunityChatNotificationsEmail(toggle: boolean) {
      const student = await apiAuth.toggleCommunityChatNotificationsEmail(
        toggle
      );
      this.user = student;
    },
    async toggleCommunityRealtimeMentionsEmail(toggle: boolean) {
      const student = await apiAuth.toggleCommunityRealtimeMentionsEmail(
        toggle
      );
      this.user = student;
    },
    async togglePrivatePodcastNotificationsEmail(toggle: boolean) {
      const student = await apiAuth.togglePrivatePodcastNotificationsEmail(
        toggle
      );
      this.user = student;
    },
    async updateTeacher(data: TeacherResponse) {
      const teacher = await apiAuth.updateTeacher(data);
      this.teacher = teacher;
    },
    async updateTeacherSignupInfo(data: SignupInfoResponse) {
      return await apiAuth.updateTeacherSignupInfo(data);
    },
    async populateTeacherStudio() {
      return await apiAuth.populateTeacherStudio();
    },
    async getTeacherAnnouncement() {
      return await apiAuth.getTeacherAnnouncement();
    },
    async updateTeacherAnnouncement(data: TeacherAnnouncementResponse) {
      return await apiAuth.updateTeacherAnnouncement(data);
    },
    async toggleWaiver(waiverState: boolean) {
      const teacher = await apiAuth.toggleTeacherWaiver(waiverState);
      this.teacher = teacher;
    },
    async toggleInstagramFeed(toggle: boolean) {
      const teacher = await apiAuth.toggleTeacherInstagramFeed(toggle);
      this.teacher = teacher;
    },
    async disconnectInstagram(toggle: boolean) {
      const teacher = await apiAuth.disconnectTeacherInstagram();
      this.teacher = teacher;
    },
    async teacherSubscribe(data:any) {
      await apiAuth.teacherSubscribe(data);
    },
    async hasPermission(permission: string) {
      await this.userInfoPromise;
      return this.teacherPermissions[permission];
    },
    async getPlans(campaign:string) {
      this.campaign = campaign;
      const plans = await apiAuth.getPlans(campaign);
      this.plans = plans;
      return plans;
    },
    async getCoachingProgramme(programmeCode: string) {
      const coachingCrogramme = await apiAuth.getCoachingProgramme(
        programmeCode
      );
      this.programme = coachingCrogramme[0];
      return coachingCrogramme;
    },
    async applyCoupon(
      { coupon, campaign }: { coupon: string; campaign: string }
    ) {
      try {
        const response: any = await apiAuth.verifyCoupon(coupon, campaign);
        const couponPlans = response.plans;
        const couponDuration = response.coupon_duration;
        const plans: any = [];
        for (const key in couponPlans) {
          if (hasOwn(couponPlans, key)) {
            const id = key.split('_')[1];
            const plan = couponPlans[key];

            for (let i = 0; i < this.userPlans.length; i++) {
              if (this.userPlans[i].id == id) {
                const planCopy = {
                  ...this.userPlans[i],
                  monthly_amount: plan.monthly,
                  yearly_amount: plan.yearly,
                  monthly_standard: plan.monthly_standard,
                  yearly_standard: plan.yearly_standard,
                  monthly_discounted: plan.monthly_discounted,
                  yearly_discounted: plan.yearly_discounted,
                  saveings:
                    this.userPlans[i].monthly_amount * 12 -
                    this.userPlans[i].yearly_amount,
                  yearlyPerMonth: this.userPlans[i].yearly_amount / 12,
                };
                plans.push(planCopy);
              }
            }
          }
        }

        this.coupon = coupon;
        this.couponPlans = plans;
        this.couponDuration = couponDuration;
      } catch (error) {
        this.clearCoupon();
        return Promise.reject(error);
      }
    },
    async applyProgrammeCoupon(
      { code, coupon }: { code: string; coupon: string }
    ) {
      const couponPlans: any = await apiAuth.verifyProgrammeCoupon(
        code,
        coupon
      );
      const programme = {
        ...this.programme,
        plan: couponPlans.plan,
        payment_plans: couponPlans.payment_plans,
        couponCode: coupon,
      };
      this.programme = programme;
    },
    clearCoupon() {
      this.coupon = null;
      this.couponPlans = null;
      this.couponDuration = null;
    },
    async getUserByUUID(uuid: string) {
      const user = await apiAuth.getUserByUUID(uuid);
      return user;
    },
    async updateUserByUUID({ uuid, user }: { uuid: string; user: UserResponse }) {
      const res = await apiAuth.updateUserByUUID(uuid, user);
      return res;
    },
    async resetConfirm(data: any) {
      const { new_password1, token, uid } = data;
      const res = await apiAuth.resetConfirm(new_password1, token, uid);
      return res;
    },
    async completeRegistration({ uuid, data }: { uuid: string; data: any }) {
      const res = await apiAuth.completeRegistration(uuid, data);
      return res;
    },
    async studentActiveSubscriptions() {
      const subscriptions = await apiAuth.studentActiveSubscriptions();
      this.subscriptions = subscriptions;
      return subscriptions;
    },
    async cancelStudentSubscription(id: string) {
      const subscription = await apiAuth.cancelStudentSubscription(id);
      return subscription;
    },
    async updateStudentSubscriptionPayment(
      { id, tk, type }: { id: string; tk: string; type: string }
    ) {
      await apiAuth.updateStudentSubscriptionPayment(id, tk, type);
    },
    async signWaiver(
      { studio_id, waiver_id }: { studio_id: string; waiver_id: string }
    ) {
      await apiAuth.signWaiver(studio_id, waiver_id);
    },
    async activateChat() {
      await apiAuth.activateChat();
    },
    async studentPunches() {
      const punches = await apiAuth.studentPunches();
      this.punches = punches;
    },
    async studentPurchases() {
      const purchases = await apiAuth.studentPurchases();
      this.purchases = purchases;
    },
    async inviteAdmin(email: string) {
      const result = await apiAuth.inviteAdmin(email);
      return result;
    },
    async removeInvitation(invitationUniqueId: string) {
      await apiAuth.removeInvitation(invitationUniqueId);
    },
    async removeTeamMember(userId: number) {
      await apiAuth.removeTeamMember(userId);
    },
    async getInvitation(invitationUniqueId: string) {
      return await apiAuth.getInvitation(invitationUniqueId);
    },
    async acceptInvitation(invitationUniqueId: string) {
      return await apiAuth.acceptInvitation(invitationUniqueId);
    },
    async downloadStudentPurchaseInvoice(purchaseId: number) {
      return apiAuth.downloadStudentPurchaseInvoice(purchaseId);
    },
    async downloadStudentSubscriptionInvoice(purchaseId: number) {
      return apiAuth.downloadStudentSubscriptionInvoice(purchaseId);
    },
    async downloadTeacherPurchaseInvoice(purchaseId: number) {
      return apiAuth.downloadTeacherPurchaseInvoice(purchaseId);
    },
    async htmlToPdf({ html, title }: { html: string; title: string }) {
      return apiAuth.htmlToPdf(html, title);
    },
    async studentIdAttendances(query: any) {
      const { studentId, q, dateFrom, dateTo, page } = query;
      return apiAuth.studentIdAttendances(studentId, q, dateFrom, dateTo, page);
    },
    async getAffiliateInfo() {
      return await apiAuth.getAffiliateInfo();
    },
    async updateAffiliateInfo(payload: any) {
      return await apiAuth.updateAffiliateInfo(payload);
    },
    async studentIdAttendancesExportToCSV(query: any) {
      const { studentId, q, dateFrom, dateTo } = query;
      const data = await apiAuth.studentIdAttendancesExportToCSV(
        studentId,
        q,
        dateFrom,
        dateTo
      );
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, `attendance-${studentId}.csv`);
    }
  },
  persist: {
    paths: ['localStudioPassword'],
  },
});
